import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, Container, Paper, Box, Link, Backdrop, Modal, CircularProgress } from '@mui/material';
import PasteIcon from '@mui/icons-material/ContentPaste';
import Papa from 'papaparse';
import TwitterIcon from '@mui/icons-material/Twitter'; 
import logoUrl from './Based Pesky.gif'; // Import logo GIF

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid',
    boxShadow: 24,
    p: 4,
    borderRadius: '8px',
    justifyContent: 'center',
    textAlign: 'center',
};

const WhitelistChecker = () => {
    const [address, setAddress] = useState('');
    const [isWhitelisted, setIsWhitelisted] = useState(null);
    const [whitelistedAddresses, setWhitelistedAddresses] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [addressError, setAddressError] = useState(false);
    const [helperText, setHelperText] = useState('');

    useEffect(() => {
        Papa.parse('/whitelist.csv', {
            download: true,
            header: true,
            complete: (results) => {
                const addresses = results.data.map(row => row.address.toLowerCase());
                setWhitelistedAddresses(addresses);
            },
            error: (error) => {
                console.error("Error loading CSV: ", error);
            }
        });
    }, []);

    const isValidEthereumAddress = (address) => /^0x[a-fA-F0-9]{40}$/.test(address);

    const checkWhitelist = () => {
        if (!address) {
            setAddressError(true);
            setHelperText('Please enter a Base address.');
            return;
        }

        if (!isValidEthereumAddress(address)) {
            setAddressError(true);
            setHelperText('Invalid Base address. Please enter a valid one.');
            return;
        }

        setAddressError(false);
        setHelperText('');
        setLoading(true);

        const addressToCheck = address.toLowerCase();

        setTimeout(() => {
            if (whitelistedAddresses.includes(addressToCheck)) {
                setIsWhitelisted(true);
                setModalMessage('This address is whitelisted!');
            } else {
                setIsWhitelisted(false);
                setModalMessage('This address is not whitelisted.');
            }
            setLoading(false);
            setOpenModal(true);
        }, 2000);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setAddress('');
    };

    return (
        <Container 
            maxWidth="sm" 
            sx={{ 
                paddingTop: '40px', 
                display: 'flex', 
                flexDirection: 'column', 
                minHeight: '100vh'
            }}>
            
            <Box display="flex" justifyContent="center" mb={2}>
                <img 
                    src={logoUrl} 
                    alt="Logo" 
                    style={{ 
                        maxWidth: '100%', 
                        height: 'auto' 
                    }}
                    sx={{ 
                        maxWidth: { xs: '70%', sm: '400px' } }}
                />
            </Box>

            <Paper 
                elevation={3} 
                sx={{ 
                    p: 2, 
                    borderRadius: '8px', 
                    backgroundColor: 'transparent', 
                    flexGrow: 1, 
                    display: 'flex',
                    flexDirection: 'column',
                    alignContent: 'center',
                    justifyContent: 'center',
                    boxShadow: 'none'
                }}>
                <Typography 
                    variant="h4" 
                    color='#fefefe'
                    align="center" 
                    gutterBottom 
                    fontFamily='poxel' 
                    sx={{ fontSize: { xs: '24px', sm: '30px' } }}
                >
                    WHITELIST CHECKER
                </Typography>
                
                <Box display="flex" alignItems="center" mb={2}>
                <TextField
                    label="Enter Base Address"
                    variant="outlined"
                    fullWidth
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    error={addressError}
                    helperText={helperText}
                    InputLabelProps={{
                        style: { color: '#fefefe', fontFamily: 'poxel' } 
                    }}
                    InputProps={{
                        style: { color: '#fefefe', borderColor: '#fefefe', fontFamily: 'poxel' }, // Ubah warna teks dan border
                    }}
                    sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#fefefe', // Border color saat normal
                                },
                                '&:hover fieldset': {
                                    borderColor: '#fefefe', // Border color saat hover
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#fefefe', // Border color saat focused
                                },
                            },
                        }}
                    />

                    <PasteIcon 
                        style={{ 
                            cursor: 'pointer', 
                            marginLeft: '10px',
                            color: '#fefefe'
                        }} 
                        onClick={() => navigator.clipboard.readText().then(text => setAddress(text))}
                    />
                </Box>
                
                <Button 
                    variant="contained" 
                    color="primary" 
                    fullWidth 
                    onClick={checkWhitelist}
                    style={{
                        fontFamily: 'poxel',
                        fontWeight: 'bold'
                    }}
                >
                    Check
                </Button>
            </Paper>

            <Box mt={5} textAlign="center" sx={{ pb: 3 }}>
                <Link 
                    href="https://x.com/BasedPesky" 
                    target="_blank" 
                    rel="noopener" 
                    color="inherit"
                    sx={{ 
                        display: 'inline-flex', 
                        alignItems: 'center', 
                        textDecoration: 'none' 
                    }}
                >
                    <TwitterIcon sx={{ 
                        verticalAlign: 'middle', 
                        mr: 1, 
                        color: '#fefefe', 
                        cursor: 'pointer' 
                        }} />
                </Link>
                
                <Typography 
                    variant="body2" 
                    color="#ffffff" 
                    fontFamily='poxel'
                >
                    &copy; {new Date().getFullYear()} Based Pesky. All rights reserved.
                </Typography>  
            </Box>


            <Backdrop 
                open={loading} 
                sx={{ 
                    zIndex: 9999, 
                    color: '#fefefe' 
                    }}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Modal
                open={openModal}
                onClose={handleCloseModal}
            >
                <Box sx={{
                    ...style,
                    borderColor: isWhitelisted ? '#00ff00' : '#ff0000'
                }}>
                    <Typography 
                        variant="h6" 
                        component="h2" 
                        sx={{ 
                            fontFamily: 'poxel', 
                            backgroundColor: '#fefefe', 
                            fontWeight: 'bold' }}
                    >
                        {modalMessage}
                    </Typography>
                    <Button 
                        onClick={handleCloseModal} 
                        variant="contained" 
                        sx={{ mt: 2 }}
                        style={{
                            fontFamily: 'poxel',
                            fontWeight: 'bold'
                        }}
                    >
                        Close
                    </Button>
                </Box>
            </Modal>
        </Container>
    );
};

export default WhitelistChecker;
