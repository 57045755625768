// src/App.js
import React from 'react';
import WhitelistChecker from './components/WhitelistChecker';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import './App.css'

const theme = createTheme({
    palette: {
        primary: {
            main: '#fefefe', 
        },
        secondary: {
            main: '#fefefe', 
        },
        background: {
            default: '#161616', 
        },
    },
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        h4: {
            fontWeight: 600,
        },
        h6: {
            fontWeight: 400,
        },
    },
});

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <WhitelistChecker />
        </ThemeProvider>
    );
};

export default App;